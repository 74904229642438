.header {
  background-color: #ffffff;
  transition: all 0.5s;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  z-index: 999;
}

.menu-toggle {
  background: none !important;
  border: none !important;
}

.user-logo {
  width: 200px;
}

.user-logo img {
  width: 100%;
}

.user-id {
  font-size: 28px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #ffffff;
  background-color: #8383ff;
  padding: 8px 22px;
}

@media (min-width:1px) and (max-width:767px) {
  .customer-name {
    font-size: 13px;
    margin-right: 35px !important;
  }

  .drop-lefts {
    margin-left: -100px !important;
  }
}

@media (min-width:768px) and (max-width:1024px) {
  .customer-name {
    font-size: 12px;
  }

  .drop-lefts {
    margin-left: -100px !important;
  }
}

@media (min-width:1024px) and (max-width:1339px) {
  .customer-name {
    font-size: 13px;
  }

  .drop-lefts {
    margin-left: -100px !important;
  }
}

@media (min-width:1440px) {
  .customer-name {
    font-size: 15px;
  }

  .drop-lefts {
    margin-left: -100px !important;
  }
}