@font-face {
  font-family: "regular";
  src: url("./assest/fonts/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

body {
  background-color: #f1f1f1 !important;
  font-family: "regular" !important;
}

.login-picther {
  background-image: url("../src/assest/images/Untitledss.jpg");
  background-size: cover;
  background-position: center;

}

.kannan1 {
  background-color: rgba(255, 255, 255, 0.354);
}

@media (max-width: 1024px) {
  .kannan {
    width: 480px !important;
  }
}

@media (min-width: 1440px) {
  .kannan {
    width: 510px !important;
  }
}

.login-logo {
  width: 300px;
}

.btnlogin {
  background-color: orange !important;
  color: #ffffff !important;
  width: 120px;
  height: 50px;
  font-size: 17px !important;
  font-weight: bolder !important;
  letter-spacing: 1.5px;
}

.main {
  background-color: #ffffff;
}

.scroll {
  height: 100vh;
  overflow-y: scroll;
}

.wordbrk {
  white-space: pre;
}

.overflow {
  overflow: scroll;
}

.filter {
  background-color: #527dff !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  font-weight: 600;
  transition: 0.5s all;
  font-size: 16px !important;
  font-family: "regular" !important;
}

.create-btn {
  border: 1px solid #527dff !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  font-weight: 600;
  transition: 0.5s all;
  font-size: 15px !important;
  font-family: "regular" !important;
  background: #527dff !important;
}

.form-icon {
  position: relative;
}

.form-cntrl {
  padding: 0.375rem 1rem !important;
  outline: none !important;
  border: 1px solid #b8c8f8 !important;
  border-radius: 8px;
  background: #e5e8f1;
}

.form-cntrllogin {
  padding: 0.375rem 1rem !important;
  outline: none !important;
  border: 1px solid #b8c8f8 !important;
  border-radius: 8px;
  background: #FFFFFF;

}

.btn-down {
  background-color: #8383ff !important;
  color: #ffffff !important;
  width: 150px !important;
  padding: 10px !important;
}

.btn-downn {
  background-color: #8383ffcc !important;
  color: #ffffff !important;
  width: 150px !important;
  padding: 10px !important;
}

.prefix-icon {
  position: absolute;
  top: 4px;
  left: 10px;
}

.suffix-icon {
  position: absolute;
  right: 10px;
  top: 4px;
}




.form-control-padboth {
  padding: 0.375rem 2.2rem !important;
}

.form-control-padleft {
  padding: 0.375rem 0 0.375rem 2.2rem !important;
}

.form-control-padright {
  padding: 0.375rem 2.2rem 0.375rem 1rem !important;
}

.counter-card {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 30px 10px;
  background-color: #fff;
  height: 150px;
  border-radius: 5px;

}

.counterturbine {
  /* box-shadow: 2px 2px 10px #dadada; */
  background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);
  height: 150px !important;
  border-radius: 10px !important;
}


.datedashboard {}

.spin {
  font-size: 4rem;
  opacity: 0.2;

}

.counter-card svg {
  font-size: 4rem;
  opacity: 0.2;
}

.spin svg {
  animation: spin 15s infinite;
  transition: 1s all;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.counter-card .count-numbers {}

.datefor {
  margin-left: 250px;
  margin-top: 80px !important;
}

/* Disable selection in Safari */
/* Disable selection in Firefox */
/* Disable selection in Internet Explorer/Edge */
/* Disable selection in standard browsers */
/* .noselect {
  -webkit-user-select: none; 
  -moz-user-select: none;    
  -ms-user-select: none;    
  user-select: none;         
} */