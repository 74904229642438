.position-relative {
  width: 120px; /* Default width for larger screens */
  height: 120px; /* Default height for larger screens */
  top: 8px !important;
}

.rotate {
  width: 110px; /* Default width for larger screens */
  animation: rotate 3s linear infinite;
  top: -47px !important;
  left: 4px;
}
.tower{
  height: 85% !important;
}
img {
  width: 100%; /* Responsive width for the image */

}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive styles for xs to md devices */
@media (max-width: 768px) {
  .position-relative {
    width: 80px; /* Smaller width for mobile devices */
    height: 80px;
    top: 20px !important;
  }

  .rotate {
    width: 70px; /* Adjust size for mobile devices */
    top: -30px !important;
    left: 2px;
  }
}

/* Small devices (up to 768px) */
@media (max-width: 768px) {
  .responsive-font {
    font-size: 0.875rem; /* Smaller font size for xs */
  }
}

/* Medium devices (768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-font {
    font-size: 0.875rem; /* Font size for md */
  }
}

/* Large devices (992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  .responsive-font {
    font-size: 0.775rem; /* Font size for lg */
  }
}

/* Extra-large devices (1200px and up) */
@media (min-width: 1200px) and (max-width: 1399px) {
  .responsive-font {
    font-size: 1.10rem; /* Font size for xl */
  }
}

/* Extra-extra-large devices (1400px and up) */
@media (min-width: 1400px) {
  .responsive-font {
    font-size: 1.25rem; /* Font size for xxl */
  }
}

