#main {
  margin-top: 45px;
  padding: 25px;
  padding-bottom: 10px !important;
  transition: 0.5s all;
}
#mainn {
  margin-top: 0px !important;
  padding: 25px;
  padding-top: 0px !important;
  transition: 0.5s all;
}
